// Font Families
$font-primary: 'Noto Sans JP', sans-serif;
$font-icons: "Material Icons", serif;

// font size
$h1-font-size: 3.6rem;
$h2-font-size: 3rem;
$h3-font-size: 2.4rem;
$h4-font-size: 2rem;
$h5-font-size: 1.6rem;
$h6-font-size: 1.4rem;

// font size p
$font-size-second: 1.5rem;
$font-size-third: 1.2rem;
$font-size-four: 1.8rem;

/**
** Custom font small
**/

$font-size-body-small: 1.4rem;
$font-size-second-small: 1.3rem;

$h1-font-size-small: 3rem;
$h2-font-size-small: 2.4rem;
$h3-font-size-small: 2rem;
$h4-font-size-small: 1.8rem;
$h5-font-size-small: 1.6rem;
$h6-font-size-small: 1.4rem;


/**
** Custom font big
**/

$font-size-body-big: 2rem;
$font-size-second-big: 2rem;

$h1-font-size-big: 4rem;
$h2-font-size-big: 3.4rem;
$h3-font-size-big: 2.8rem;
$h4-font-size-big: 2.4rem;
$h5-font-size-big: 2rem;
$h6-font-size-big: 1.8rem;