@import "../variables-site/variables";

// Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "node_modules/bootstrap/scss/functions";

// Include any default variable overrides here

// body
$body-bg: $background-body;
$body-color: $color-primary;
$body-text-align: inherit;

// stylelint-enable value-keyword-case
$font-family-sans-serif: $font-primary;
$font-family-monospace: $font-primary;
$font-family-base: $font-primary;
$font-size-base: 1.8rem;
$line-height-base: 1.5;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-gutter-width: 2.4rem;

// Links
//
// Style anchor elements.
$link-color: $color-link;
$link-hover-color: $color-hover;
$link-decoration: none;

// scss-docs-end caret-variables
$transition-base: all 0.4s ease-in-out;

// font size
$h1-font-size: $h1-font-size;
$h2-font-size: $h2-font-size;
$h3-font-size: $h3-font-size;
$h4-font-size: $h4-font-size;
$h5-font-size: $h5-font-size;
$h6-font-size: $h6-font-size;

// headings-variables
$headings-margin-bottom: 1.2rem;
$headings-font-weight: 700;


// Paragraphs
//
// Style p element.
$paragraph-margin-bottom: 1.2rem;

// Navs
// scss-docs-start nav-variables
$nav-link-font-size: $font-size-base;
$nav-link-font-weight: 40;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
		sm: 100%,
		md: 100%,
		lg: 100%,
		xl: 1140px,
		xxl: 1224px
) !default;

// Include remainder of required Bootstrap stylesheets
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/variables-dark";
@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/utilities";

// Include any optional Bootstrap components as you like
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/toasts";

// Helpers
@import "node_modules/bootstrap/scss/helpers";

// Utilities
@import "node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack