@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes mulShdSpin {

    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #f11b0b3d, 1.8em -1.8em 0 0em #f11b0bb0, 2.5em 0em 0 0em #f11b0bb0, 1.75em 1.75em 0 0em #f11b0bb0, 0em 2.5em 0 0em #f11b0bb0, -1.8em 1.8em 0 0em #f11b0bb0, -2.6em 0em 0 0em #F11B0B, -1.8em -1.8em 0 0em red;
    }

    12.5% {
        box-shadow: 0em -2.6em 0em 0em red, 1.8em -1.8em 0 0em #f11b0b3d, 2.5em 0em 0 0em #f11b0bb0, 1.75em 1.75em 0 0em #f11b0bb0, 0em 2.5em 0 0em #f11b0bb0, -1.8em 1.8em 0 0em #f11b0bb0, -2.6em 0em 0 0em #f11b0bb0, -1.8em -1.8em 0 0em #F11B0B;
    }

    25% {
        box-shadow: 0em -2.6em 0em 0em #F11B0B, 1.8em -1.8em 0 0em red, 2.5em 0em 0 0em #f11b0b3d, 1.75em 1.75em 0 0em #f11b0bb0, 0em 2.5em 0 0em #f11b0bb0, -1.8em 1.8em 0 0em #f11b0bb0, -2.6em 0em 0 0em #f11b0bb0, -1.8em -1.8em 0 0em #f11b0bb0;
    }

    37.5% {
        box-shadow: 0em -2.6em 0em 0em #f11b0bb0, 1.8em -1.8em 0 0em #F11B0B, 2.5em 0em 0 0em red, 1.75em 1.75em 0 0em #f11b0b3d, 0em 2.5em 0 0em #f11b0bb0, -1.8em 1.8em 0 0em #f11b0bb0, -2.6em 0em 0 0em #f11b0bb0, -1.8em -1.8em 0 0em #f11b0bb0;
    }

    50% {
        box-shadow: 0em -2.6em 0em 0em #f11b0bb0, 1.8em -1.8em 0 0em #f11b0bb0, 2.5em 0em 0 0em #F11B0B, 1.75em 1.75em 0 0em red, 0em 2.5em 0 0em #f11b0b3d, -1.8em 1.8em 0 0em #f11b0bb0, -2.6em 0em 0 0em #f11b0bb0, -1.8em -1.8em 0 0em #f11b0bb0;
    }

    62.5% {
        box-shadow: 0em -2.6em 0em 0em #f11b0bb0, 1.8em -1.8em 0 0em #f11b0bb0, 2.5em 0em 0 0em #f11b0bb0, 1.75em 1.75em 0 0em #F11B0B, 0em 2.5em 0 0em red, -1.8em 1.8em 0 0em #f11b0b3d, -2.6em 0em 0 0em #f11b0bb0, -1.8em -1.8em 0 0em #f11b0bb0;
    }

    75% {
        box-shadow: 0em -2.6em 0em 0em #f11b0bb0, 1.8em -1.8em 0 0em #f11b0bb0, 2.5em 0em 0 0em #f11b0bb0, 1.75em 1.75em 0 0em #f11b0bb0, 0em 2.5em 0 0em #F11B0B, -1.8em 1.8em 0 0em red, -2.6em 0em 0 0em #f11b0b3d, -1.8em -1.8em 0 0em #f11b0bb0;
    }

    87.5% {
        box-shadow: 0em -2.6em 0em 0em #f11b0bb0, 1.8em -1.8em 0 0em #f11b0bb0, 2.5em 0em 0 0em #f11b0bb0, 1.75em 1.75em 0 0em #f11b0bb0, 0em 2.5em 0 0em #f11b0bb0, -1.8em 1.8em 0 0em #F11B0B, -2.6em 0em 0 0em red, -1.8em -1.8em 0 0em #f11b0b3d;
    }
}